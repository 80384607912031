import { useErrorHandling } from '@/composables'
import { ENDPOINTS } from '@/endpoints'
import type { APIResponse } from '@/types'
import type {
    MarketDataCoreInflationAnnual,
    MarketDataCoreInflationQuarter,
    MarketDataCoreInflationMonthly,
} from '@/types'
import axios from 'axios'

export const getMarketDataCoreInflationAnnual = async () => {
    const request = axios.get<APIResponse<MarketDataCoreInflationAnnual>>(
        `${ENDPOINTS.MARKET_DATA_V1}/core-inflation/annual`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataCoreInflationQuarter = async () => {
    const request = axios.get<APIResponse<MarketDataCoreInflationQuarter>>(
        `${ENDPOINTS.MARKET_DATA_V1}/core-inflation/quarter`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataCoreInflationMonthly = async () => {
    const request = axios.get<APIResponse<MarketDataCoreInflationMonthly>>(
        `${ENDPOINTS.MARKET_DATA_V1}/core-inflation/monthly`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}

export const getMarketDataCoreInflationMonthlyBreakdown = async () => {
    console.log('getMarketDataCoreInflationMonthlyBreakdown')
    const request = axios.get<APIResponse<MarketDataCoreInflationMonthly>>(
        `${ENDPOINTS.MARKET_DATA_V1}/core-inflation/monthly-breakdown`,
    )

    const response = await useErrorHandling(request)

    return response.data.data
}
