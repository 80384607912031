import { importHighcharts } from '@/lib/highcharts'
import { BlockModule } from '../common'
import {
    baseLineChartConfig,
    doIfInViewOrDefer,
    setupChartCounters,
    trendColorClasses,
    trendIconClasses,
} from './common'
import { getMarketDataExternalSectorReserves } from '@/api'
import { computed, defineComponent, onBeforeUnmount, onMounted, ref, unref, watch } from 'vue'
import ChartShareButtons from '@/components/website/ChartShareButtons.vue'
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from '@/components/ui/select'
import { computedAsync } from '@vueuse/core'
import { format } from 'date-fns'
import { useBlockContext } from '../common/composables'
import type { Chart, SeriesOptionsType } from 'highcharts'

new BlockModule({
    selector: '.block-market-data-external-sector-reserves',
    vueComponent: defineComponent({
        components: {
            ChartShareButtons,
            ShadcnSelect: Select,
            SelectContent,
            SelectGroup,
            SelectItem,
            SelectLabel,
            SelectTrigger,
            SelectValue,
        },
        setup() {
            const el = document.querySelector('.block-market-data-external-sector-reserves')
            if (!el) return
            const chartContainerRef = ref<HTMLElement>()
            const year = ref(new Date().getFullYear().toString())
            let chartInstance: Chart | null = null
            const blockContext = useBlockContext()

            const dataAsync = computedAsync(async (onCancel) => {
                const abortController = new AbortController()

                onCancel(() => abortController.abort())

                const data = await getMarketDataExternalSectorReserves(
                    unref(year),
                    abortController.signal,
                )

                return data
            })

            const lastUpdatedLabel = computed(() => {
                if (!dataAsync.value) return
                return format(dataAsync.value.lastUpdated, 'PP')
            })

            const seriesData = computed<SeriesOptionsType[]>(() => {
                if (!dataAsync.value) return []
                return [
                    {
                        name: dataAsync.value.yLabel,
                        type: 'line',
                        color: '#010066',
                        data: dataAsync.value.data.map((point) => point.y),
                    },
                ]
            })

            const categories = computed(() => {
                if (!dataAsync.value) return []

                return dataAsync.value.data.map((point) => String(point.x))
            })

            const setupChart = async () => {
                const chartEl = chartContainerRef.value
                if (!chartEl) return
                const Highcharts = await importHighcharts()

                chartInstance = Highcharts.chart({
                    ...baseLineChartConfig,
                    chart: {
                        ...baseLineChartConfig.chart,
                        renderTo: chartEl as HTMLElement,
                    },
                    xAxis: {
                        ...baseLineChartConfig.xAxis,
                        type: 'category',
                        categories: [],
                    },
                    exporting: {
                        enabled: false,
                        filename: 'market-data-external-sector-reserves',
                    },
                    series: [],
                    yAxis: {
                        ...baseLineChartConfig.yAxis,
                    },
                })
            }

            const onDownload = () => {
                chartInstance?.downloadCSV()
            }

            const updateChart = () => {
                chartInstance?.update(
                    {
                        series: seriesData.value,
                        xAxis: {
                            type: 'category',
                            categories: categories.value,
                            lineColor: '#DBDBDB',
                        },
                    },
                    true,
                    true,
                    true,
                )
            }

            onMounted(async () => {
                await setupChart()

                if (blockContext?.isInView) {
                    updateChart()
                }
            })

            onBeforeUnmount(() => chartInstance?.destroy())

            watch(
                () => ({ series: seriesData.value, categories: categories.value }),
                () => {
                    if (!chartInstance) return
                    updateChart()
                },
            )

            watch(dataAsync, (newVal) => {
                if (newVal.data && newVal.data.length > 0) {
                    const latestValue = dataAsync.value.data[dataAsync.value.data.length - 1]
                    const beforeLatestValue = dataAsync.value.data[dataAsync.value.data.length - 2]
                    const valueDelta = (latestValue.y || 0) - (beforeLatestValue.y || 0)

                    const timestamp = dataAsync.value.lastUpdated
                    const date = new Date(timestamp)
                    const lastUpdatedValue = date
                        .toLocaleDateString('en-US', {
                            day: 'numeric',
                            month: 'short',
                            year: 'numeric',
                        })
                        .replace(',', '')
                    let trend: keyof typeof trendColorClasses & keyof typeof trendIconClasses =
                        'neutral'

                    if (valueDelta > 0) {
                        trend = 'positive'
                    } else if (valueDelta < 0) {
                        trend = 'negative'
                    }

                    setupChartCounters(
                        el,
                        trend,
                        latestValue,
                        beforeLatestValue,
                        null,
                        valueDelta,
                        timestamp,
                        lastUpdatedValue,
                        null,
                        null,
                        null,
                        null,
                        false,
                    )
                }
            })

            if (blockContext && !blockContext.isInView) {
                doIfInViewOrDefer(() => {
                    updateChart()
                }, blockContext)
            }

            return {
                year,
                chartContainerRef,
                lastUpdatedLabel,
                onDownload,
            }
        },
    }),
})
