import gsap from 'gsap'
import type { BlockContext } from '../common'
import type { MarketDataChartCoordinate } from '@/types/marketData'
import Highcharts from 'highcharts'
import { format, isValid, parseISO } from 'date-fns'

export const trendColorClasses = {
    positive: 'text-primary',
    negative: 'text-primary',
    neutral: 'text-lightBlue',
}

export const trendIconClasses = {
    positive: 'rotate-180',
    negative: 'rotate-0',
    neutral: '-rotate-90',
}

export const chartLineColors = [
    '#010066',
    '#91C8F3',
    '#686868',
    '#E57315',
    '#D150FF',
    '#15D9E5',
    '#212529',
]

export const countTo = (
    to: number,
    onUpdate: (value: number) => void,
    onComplete?: () => void,
    from = 0.0,
    durationInSeconds = 2,
) => {
    gsap.to(
        { value: from },
        {
            value: to,
            duration: durationInSeconds,
            ease: 'power1.out',
            onUpdate: function () {
                onUpdate(this.targets()[0].value.toFixed(2))
            },
            onComplete: onComplete,
        },
    )
}

export const doIfInViewOrDefer = (callback: () => void, context: BlockContext) => {
    if (context.isInView.value) {
        callback()
        return
    }

    context.onInView(callback)
}

export const setupChartCounters = (
    el: Element,
    trend: keyof typeof trendColorClasses,
    latestValue: MarketDataChartCoordinate,
    beforeLatestValue: MarketDataChartCoordinate,
    beforeLatestValueFormat: string | null,
    valueDelta: number,
    timestamp: string,
    lastUpdatedValue: string,
    lastUpdatedValueFormat: string | null,
    latestValuePrefix: string | null,
    latestValueSuffix: string | null,
    decimalPlace: number | null,
    showZeroDecimal: boolean,
) => {
    const appliedDecimalPlace = decimalPlace ? decimalPlace : 1

    const isValidYear = (val: string) => {
        const parsed = parseInt(val)

        if (isNaN(parsed)) return false

        return parsed > 0 && parsed < 9999
    }

    const tryParseDate = (val: string) => {
        try {
            const parsed = parseISO(val)

            if (!isValid(parsed)) return null

            return parsed
        } catch {
            return null
        }
    }

    const onCurrentValCountComplete = () => {
        const latestValueEl = el.querySelector('.latest-date-range')

        if (latestValueEl) {
            let textContent = ''
            const latestVal = latestValue.x
            const prefix = latestValuePrefix ? `${latestValuePrefix} ` : ''
            const suffix = latestValueSuffix ? ` ${latestValueSuffix}` : ''

            if (tryParseDate(latestVal as string)) {
                const dateVal = parseISO(latestVal as string)

                if (lastUpdatedValueFormat != '' && lastUpdatedValueFormat != null) {
                    textContent = `(${prefix}${format(dateVal, lastUpdatedValueFormat)}${suffix})`
                } else {
                    textContent = `(${prefix}${format(dateVal, 'd MMM yyyy')}${suffix})`
                }
            } else if (isValidYear(latestVal as string)) {
                textContent = `(${prefix}${latestVal}${suffix})`
            } else {
                textContent = `(${prefix}${latestVal}${suffix})`
            }

            latestValueEl.textContent = textContent
            latestValueEl.classList.add('opacity-100')
        }
    }

    const onPrevValCountComplete = () => {
        const previousValueLabel = el.querySelector('.previous-value-label')

        if (previousValueLabel) {
            let textContent = ''
            const prevVal = beforeLatestValue.x

            if (tryParseDate(prevVal as string)) {
                const dateVal = parseISO(prevVal as string)

                if (beforeLatestValueFormat != '' && beforeLatestValueFormat != null) {
                    textContent = `(vs ${format(dateVal, beforeLatestValueFormat)})`
                } else {
                    textContent = `(vs ${format(dateVal, 'd MMM yyyy')})`
                }
            } else if (isValidYear(prevVal as string)) {
                textContent = `(vs ${prevVal})`
            } else {
                textContent = `(vs ${prevVal})`
            }

            previousValueLabel.textContent = textContent
            previousValueLabel.classList.add('opacity-100')
        }
    }

    const previousValue = el.querySelector('.previous-value')
    if (previousValue) {
        previousValue.classList.add(trendColorClasses[trend])
    }

    const previousValueIcon = el.querySelector('.previous-value-icon')
    if (previousValueIcon) {
        previousValueIcon.classList.remove('rotate-0')
        previousValueIcon.classList.remove('rotate-180')
        previousValueIcon.classList.add(trendIconClasses[trend])
    }

    const currentValueEl = el.querySelector('.current-value-counter')
    if (currentValueEl) {
        countTo(
            latestValue.y || 0,
            (val) => {
                if (Math.abs(val) >= 1e9 || Math.abs(val) >= 1e6) {
                    const displayCurrVal = Number(val / 1e9).toFixed(appliedDecimalPlace)
                    currentValueEl.textContent = showZeroDecimal
                        ? displayCurrVal
                        : displayCurrVal.replace(/\.0+$/, '')
                } else {
                    const displayCurrVal = Number(val).toFixed(appliedDecimalPlace)
                    currentValueEl.textContent = showZeroDecimal
                        ? displayCurrVal
                        : displayCurrVal.replace(/\.0+$/, '')
                }
            },
            onCurrentValCountComplete,
        )
    }

    const previousValueEl = el.querySelector('.previous-value-counter')
    if (previousValueEl) {
        countTo(
            Math.abs(valueDelta),
            (val) => {
                if (Math.abs(val) >= 1e9 || Math.abs(val) >= 1e6) {
                    const displayPrevVal = Number(val / 1e9).toFixed(appliedDecimalPlace)
                    previousValueEl.textContent = showZeroDecimal
                        ? displayPrevVal
                        : displayPrevVal.replace(/\.0+$/, '')
                } else {
                    const displayPrevVal = Number(val).toFixed(appliedDecimalPlace)
                    previousValueEl.textContent = showZeroDecimal
                        ? displayPrevVal
                        : displayPrevVal.replace(/\.0+$/, '')
                }
            },
            onPrevValCountComplete,
        )
    }

    const lastUpdatedEl = el.querySelector('.last-updated')
    if (lastUpdatedEl && timestamp) {
        lastUpdatedEl.textContent = `Last updated: ${lastUpdatedValue}`
    }
}

export const baseLineChartConfig: Highcharts.Options = {
    chart: {
        type: 'line',
        marginTop: 50,
    },
    legend: {
        align: 'right',
        verticalAlign: 'top',
        layout: 'horizontal',
    },
    title: {
        text: '',
    },
    xAxis: {
        lineColor: '#DBDBDB',
    },
    yAxis: {
        color: '#DBDBDB',
        title: {
            text: 'RM bil',
            align: 'high',
            rotation: 0,
            reserveSpace: false,
            textAlign: 'left',
            y: -20,
        },
    },
    responsive: {
        rules: [
            {
                condition: {
                    maxWidth: 1024,
                },
                chartOptions: {
                    legend: {
                        align: 'center',
                        verticalAlign: 'bottom',
                    },
                },
            },
        ],
    },
}
